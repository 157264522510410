.gallery {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  &__menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    &-item {
      font-family: Myriad;
      font-size: 30px;
      font-stretch: condensed;
      background-color: transparent;
      box-shadow: none;
      margin-left: 70px;
      color: $lightGray;
      transition: all 0.15s ease-in-out;

      &:hover {
        color: $gray;
        cursor: pointer;
      }

      &--active {
        color: $darkGray;
      }

      &:first-child {
        margin-left: 0;
      }

      &--dark {
        color: $darkGray;
        &.gallery__menu-item--active {
          color: $lightGray;
        }
      }
    }
  }

  &__content {
    z-index: 10;
    width: 100%;
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
    transition: all 0.15s ease-in-out;

    &--hidden {
      opacity: 0;
    }
  }

  &__column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }

  &__image {
    width: 100%;
    border-radius: 20px;
    margin-bottom: 30px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.15s ease-in-out;

    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
  }

  &__loading {
    margin-top: 100px;
    position: absolute !important;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;

    &--hidden {
      opacity: 0;
    }
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 144px;
  height: 144px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 128px;
  height: 128px;
  margin: 12px;
  border: 12px solid #000000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ff0000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ril__image {
  border-radius: 3vw;
}