.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  padding-bottom: 20px;
  &__wrapper {
    width: 80%;
  }
  &__header {
    position: relative;
    width: 100%;
    background-color: $lightGray;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 50px 4px 240px;
    max-height: 35px;
    transition: all 0.5s ease-in-out;
  }
  &__laptop {
    transition: all 0.5s ease-in-out;
    width: 200px;
    height: 140px;
    position: absolute;
    left: 20px;
    bottom: -30px;
    background-size: contain;
    background-repeat: no-repeat;

    &:hover {
      cursor: pointer;
    }
  }
  &__title {
    color: white;
    text-transform: uppercase;

    &-icon {
      width: 50px;
      margin-left: 5px;
      padding-bottom: 5px;
    }
    &-text {
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--lowercase {
      width: 200px;
      text-align: right;
      text-transform: lowercase;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      max-height: 35px;
    }
  }
  &__content {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  &__footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 20px;
    padding-right: 50px;
    font-size: 14px;

    .footer__title {
      display: none;
    }
  }
  &__text {
    width: 200px;
    text-align: right;
    color: $gray;
    text-decoration: none;
    padding-right: 8px;
    &-link {
      transition: all 0.15s ease-in-out;

      &:hover {
        color: black;
      }
    }
  }
}

@media (max-width: 1279px) {
  .footer {
    &__wrapper {
      width: 90%;
    }
    &__title {
      &--lowercase {
        width: 20vw;
      }
    }
    &__text {
      width: 20vw;
    }
  }
}

@media (max-width: 1023px) {
  .footer {
    &__wrapper {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    &__header {
      width: 100%;
      max-width: 400px;
      padding: 8px 20px 4px;
      justify-content: center;
    }
    &__content {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      &--top {
        display: none;
      }
    }
    &__footer {
      margin-top: 80px;
      padding-top: 0;
      padding-right: 0;
      justify-content: center;

      .footer__title {
        display: flex;
      }
    }
    &__laptop {
      position: static;
      width: 150px;
      height: 105px;
    }
    &__title {
      &--lowercase {
        color: black;
        width: 100%;
        transition: all 0.5s ease-in-out;
      }
    }
    &__text {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}
