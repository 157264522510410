.subpage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
  margin-top: -10px;
  &__wrapper {
    width: 70%;
  }

  &__header {
    font-size: 2.4vw;
    font-family: Garamond;
    font-style: italic;
    color: $gray;
    padding-left: 10px;
    margin-bottom: 15px;
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__image {
    min-width: 13vw;
    width: 13vw;
    height: 13vw;
    background-position: center;
    background-size: cover;
    margin-bottom: 15px;
    border-radius: 15px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
    margin-right: 40px;
  }

  &__line {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: black;
    margin: 20px 0 30px;

    &::before {
      border-radius: 50%;
      position: absolute;
      top: -8px;
      left: 0;
      content: '';
      width: 17px;
      height: 17px;
      background-color: $lightGray;
    }

    &--light {
      background-color: white;
    }
  }

  &__text {
    font-weight: 300;
    p {
      margin-bottom: 15px;

      strong {
        font-weight: 400;
      }
    }
  }

  &__back {
    height: 12vw;
    &-wrapper {
      position: absolute;
      top: 0.5vw;
      left: -8vw;
      padding: 0 4vw;
      transition: all 0.15s ease-in-out;
      opacity: 0.6;
      &:hover {
        // transform: scale(1.2);
        opacity: 0.8;
        left: -9vw;
      }
    }
  }
}

@media (max-width: 767px) {
  .subpage {
    &__wrapper {
      width: 90%;
    }
    &__content {
      flex-direction: column;
      align-items: center;
    }
    &__header {
      font-size: 24px;
      margin-top: 15px;
      padding-left: 0;
      margin-bottom: 10px;
      text-align: center;
    }
    &__image {
      width: 100%;
      height: 50vw;
      max-width: 50vw;
      margin-right: 0;
      margin: 10px 0 30px;
    }
    &__back {
      height: 30vw;
      &-wrapper {
        top: 10vw;
        left: 5vw;
        padding: 0 5vw;
        transition: all 0.15s ease-in-out;
        opacity: 0.6;
        &:hover {
          // transform: scale(1.2);
          opacity: 0.8;
          left: 4vw;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .subpage {
    &__image {
      height: 50vw;
      max-width: 50vw;
    }
  }
}
