@import 'colors';
@import 'fonts';

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

#root,
html,
body {
  width: 100%;
  min-height: 100%;
  font-family: Myriad;
  overflow-anchor: none
}

.app {
  width: 100%;
  min-height: 100vh;
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  padding-top: 14vw;
  transition: all 0.5s ease-in-out;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

@media (max-width: 767px) {
  .app {
    padding-top: 100px;
  }
}

@import 'header';
@import 'main';
@import 'subpage';
@import 'gallery';
@import 'footer';
