//Garamond

@font-face {
  font-family: Garamond;
  font-weight: 400;
  font-style: italic;
  src: url('./../assets/fonts/AGaramondPro-Italic.otf');
}

@font-face {
  font-family: Garamond;
  font-weight: 500;
  font-style: italic;
  src: url('./../assets/fonts/AGaramondPro-SemiboldItalic.otf');
}

//Myriad

@font-face {
  font-family: Myriad;
  font-weight: 300;
  src: url('./../assets/fonts/MyriadPro-Light.otf');
}

@font-face {
  font-family: Myriad;
  font-weight: 400;
  src: url('./../assets/fonts/MyriadPro-Regular.otf');
}

@font-face {
  font-family: Myriad;
  font-weight: 400;
  font-stretch: condensed;
  src: url('./../assets/fonts/MyriadPro-Cond.otf');
}
