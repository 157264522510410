.main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  overflow: hidden;
  position: relative;
  &__wrapper {
    width: 58.5%;
  }
  &__slider {
    &-nav {
      position: absolute;
      box-sizing: content-box;
      height: 13vw;
      align-self: flex-start;
      top: 0;
      padding-top: 5.2vw;
      padding-bottom: 200px;
      transition: all 0.15s ease-in-out;
      opacity: 0.5;
      z-index: 999;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
    &-prev {
      left: 0;
      padding-left: 17%;
      padding-right: 2%;

      &:hover {
        cursor: pointer;
        padding-left: 16%;
        padding-right: 3%;
      }
    }
    &-next {
      right: 0;
      padding-left: 2%;
      padding-right: 17%;
      &:hover {
        cursor: pointer;
        padding-left: 3%;
        padding-right: 16%;
      }
    }
  }
  &__item {
    text-decoration: none;
    &-wrapper {
      padding: 0 20px;
      position: relative;

      &:hover {
        cursor: pointer;

        .main__image {
          transform: scale(1.05);
          box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);
        }
      }

      &--dark {
        &::after {
          background-color: white;
        }
      }
    }
    &-separator {
      &::before {
        position: absolute;
        top: 62px;
        content: "";
        width: 17px;
        height: 17px;
        background-color: $lightGray;
        z-index: 10;
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        top: 70px;
        content: "";
        width: 1px;
        height: 25vw;
        background-color: #555555;
        transition: all 0.15s ease-in-out;
      }

      &--left {
        &::before {
          left: -9px;
        }
        &::after {
          left: -1px;
        }
      }

      &--right {
        &::before {
          right: -8px;
        }
        &::after {
          right: 0;
        }
      }
    }
  }
  &__title {
    font-size: 2.4vw;
    font-family: Garamond;
    font-style: italic;
    color: $gray;
    padding-left: 10px;
    text-transform: lowercase;
  }
  &__image {
    margin-top: 15px;
    width: 100%;
    height: 16vw;
    background-position: center;
    background-size: cover;
    margin-bottom: 15px;
    border-radius: 15px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.15s ease-in-out;
    cursor: pointer;
  }
  &__text {
    display: -webkit-box;
    margin-top: 30px;
    font-size: 16px;
    color: black;
    max-height: 190px;
    -webkit-mask-image: linear-gradient(180deg, #000000 0%, transparent);
    text-overflow: "";
    -webkit-box-orient: vertical;
    font-weight: 300;
    transition: all 0.5s ease-in-out;

    p {
      margin-bottom: 20px;
    }
  }
}

.slick-slide {
  filter: saturate(0%);
  transition: filter 0.25s ease-in-out, opacity 0.25s ease-in-out;
  opacity: 0.1;

  &.slick-active {
    filter: saturate(100%);
    opacity: 1;
  }
}

.slick-list {
  overflow: visible;
}

@media (max-width: 1023px) {
  .main {
    margin-top: 0;
    &__wrapper {
    }
    &__image {
      height: 15vw;
    }
    &__text {
      font-size: 12px;
      margin-top: 15px;

      p {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .main {
    margin-top: 0;
    &__wrapper {
      width: 60%;
    }
    &__slider {
      &-nav {
        height: 40vw;
        top: 0;
        padding-top: 16vw;
        padding-bottom: 200px;

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
      &-prev {
        left: 0;
        padding-left: 12%;
        padding-right: 7%;

        &:hover {
          cursor: pointer;
          padding-left: 11%;
          padding-right: 8%;
        }
      }
      &-next {
        right: 0;
        padding-left: 7%;
        padding-right: 12%;
        &:hover {
          cursor: pointer;
          padding-left: 8%;
          padding-right: 11%;
        }
      }
    }
    &__title {
      font-size: 20px;
    }
    &__item {
      &-wrapper {
        padding: 0 10px;
      }
      &-separator {
        &::before {
          top: 62px;
          width: 9px;
          height: 9px;
        }
        &::after {
          top: 70px;
          height: 52vw;
        }

        &--left {
          &::before {
            left: -5px;
          }
          &::after {
          }
        }
        &--right {
          &::before {
            right: -4px;
          }
          &::after {
          }
        }
      }
    }
    &__image {
      height: 52vw;
      margin: 10px 0;
    }
    &__text {
      font-size: 14px;
      margin-top: 15px;

      p {
        margin-bottom: 10px;
      }
    }
  }
}
