.header {
  width: 100%;
  height: 13.5vw;
  background-image: url("./../assets/images/ribbon.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: all 1s ease-in-out;

  &__phone {
    color: #bbbbbb;
    text-decoration: none;
  }

  &__content {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: all 1s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
  }

  &__text {
    color: #bbbbbb;
    width: 60%;
    text-align: justify;

    &--white {
      color: white;
    }
  }

  &--active {
    padding-top: 0;
    height: calc(13.5vw + 200px);
    .header__strip {
      // opacity: 0;
    }

    .header__content {
      height: 200px;
      opacity: 1;
    }

    .header__arrow {
      transform: scaleY(-1);
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 3.5vw;
  }

  &__strip {
    width: 100%;
    height: 22px;
    max-height: 22px;
    background-color: $red;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transition: all 1s ease-in-out;

    &-wrapper {
      width: 70%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__logo {
    width: 6vw;
    transition: all 0.15s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }

    &-wrapper {
      line-height: 0;
      cursor: pointer;
    }
  }

  &__arrow {
    width: 13vw;
    margin-top: 0.8vw;
    margin-bottom: 0vw;
    transition: all 1s ease-in-out;
    cursor: pointer;
  }

  &__button {
    background: none;
    padding-top: 5px;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    &-image {
      width: 70px;
    }

    &:last-child {
      margin-left: 20px;
    }
  }
}

@media (max-width: 1023px) {
  .header {
    &__strip {
      height: 15px;
      max-height: 15px;
    }

    &__button {
      &-image {
        width: 50px;
      }
    }
  }
}

@media (max-width: 767px) {
  .header {
    height: 100px;
    background-size: cover;

    &__wrapper {
      margin-bottom: 20px;
    }
    &__strip {
      &-wrapper {
        width: 90%;
        justify-content: space-between;
      }
    }

    &__logo {
      width: 50px;
    }

    &__arrow {
      width: 100px;
    }

    &__button {
      &-image {
        width: 50px;
      }
      &:last-child {
        margin-left: 0px;
      }
    }
  }
}
